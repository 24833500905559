/* Establece la imagen de fondo */
[data-amplify-authenticator] {
  background-image: url('../../images/BackgroundB1Z.jpg');
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ajusta el contenedor del formulario */
[data-amplify-authenticator] [data-amplify-container] {
  background-color: #fff;
  padding: 70px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border: 0;

}
[data-amplify-authenticator] [data-amplify-router] {
  background: none;
  border: none !important;
  box-shadow: none !important;
}

/* Cambiar color de los botones y links a #fff */
.amplify-button--primary,
.amplify-tabs__item--active,
.amplify-button--link {
  --amplify-internal-button-background-color: #0275d8;
  --amplify-internal-button-color: #fff;
  color: #fff;
}

.amplify-button--primary:hover {
  --amplify-internal-button-background-color: #025aa5;
  color: white;
}


.amplify-button:hover {
  --amplify-internal-button-background-color: #025aa5;
  --amplify-internal-button-color: white;
  color: white;
}



/* Otros ajustes */
.login-form-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 2rem;
  color: #0260b2;
}

.login-form-field input {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.5rem;
  font-size: 1.2rem;
  width: 100%;
}

.login-footer {
  margin-top: 3rem;
  text-align: center;
  border-top: 1px solid #444444;
  color: #000;
}

.amplify-label {
  color: hsl(0, 0%, 0%);
}

.amplify-tabs__item {
  color: black;
}

.amplify-icon {
  color: black;
}

.login-form-policy {
  color:black;
}

img.logo-login {
  width: 100px;
  height: 100px;
}

/* Hacer responsive */
@media (max-width: 768px) {
  .login-form-title {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .login-form-field input {
    font-size: 1rem;
  }

  .login-footer {
    margin-top: 2rem;
  }

  img.logo-login {
    width: 120px;
    height: 120px;
  }

  [data-amplify-authenticator] [data-amplify-router] {
    padding: 70px 15px 15px 15px;
  }
}

@media (max-width: 480px) {
  .login-form-title {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .login-form-field input {
    font-size: 0.9rem;
  }

  .login-footer {
    margin-top: 1.5rem;
  }


  [data-amplify-authenticator] [data-amplify-router] {
    padding: 70px 15px 15px 15px;
  }

  .login-form-policy {
    color: black;
    width: 80%;
    margin: auto;
}
}

.amplify-input{
  color: black;
}

.amplify-tabs__item--active {
  color:#0275d8 ;
  border-color: #0275d8;
}

.amplify-tabs__item:hover {
  color: #025aa5;
}

.amplify-tabs__item:active {
  border-color: #0260b2;
  box-shadow: var(--amplify-components-tabs-item-active-box-shadow);
  color: #0260b2;
}

.amplify-text--error {
  color: hsl(0deg 100% 48.82%);
}



.amplify-heading {
  color: #0260b2;
  text-align: center;
}

.amplify-input:focus {
  border-color: white;
}