/* MenuUser.css */
.menu-user {
  width: 351px;
  background-color: #091c36;
  border: 1px solid #18365c;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(255, 152, 152, 0.1);
  padding: 20px;
  box-sizing: border-box;
  position: absolute;
  top: 34px;
  right: 0px;
  color: white;
  z-index: 5;
}

.menu-links li a {
  color: white;
}


.user-info span {
  margin-right: 10px;
}

.user-info span:last-child {
  margin-right: 0;
}

.menu-links {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-links li {
  padding: 5px 0;
  cursor: pointer;
}

.menu-buttons {
  margin-top: 15px;
}

.logout-button {
  margin-right: 10px;
  padding: 1px 15px;
  border: none;
  background-color: #fff;
  color: #0260b2;
  border-radius: 5px;
  cursor: pointer;
}